import Link from 'components/atoms/link'
import Img from 'gatsby-image'
import GoogleMapReact from 'google-map-react'
import FinderContext from 'helpers/finderContext'
import { isJapanese, regions } from 'helpers/locale'
import { useResourceTextData } from 'hooks/useResourceTextData'
import Close from 'images/svg/close.svg'
import Coffee from 'images/svg/coffee.svg'
import LinkIcon from 'images/svg/link.svg'
import Roastery from 'images/svg/roastery.svg'
import React, {
  SyntheticEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { GeoSearchProvided } from 'react-instantsearch-core'
import { connectGeoSearch } from 'react-instantsearch-dom'
import styled, { css, CSSProp } from 'styled-components'
import { PointFeature } from 'supercluster'
import useSupercluster from 'use-supercluster'

const MIN_ZOOM = 2
const MAX_ZOOM = 20
const DEFAULT_ZOOM = 10
const USER_LOCATION_ZOOM = 15
const DEFAULT_CENTER = {
  lat: 0,
  lng: 0,
}
const GOOGLE_MAPS_API_INITIAL_DELAY = 500

const StyledMapMarker = styled.div`
  ${({ theme }): CSSProp => css`
    position: relative;
    width: 5.8rem;
    height: 5.8rem;
    transform: translate(-50%, -50%);
    svg {
      height: 100%;
      cursor: pointer;
    }
    .popup {
      position: absolute;
      z-index: 1;
      left: 50%;
      bottom: 100%;
      transform: translateX(-50%);
      width: 320px;
      background-color: white;
      display: flex;
      box-shadow: 0 5px 5px 0 rgba(46, 32, 28, 0.31);
      margin-bottom: 2rem;

      .gatsby-image-wrapper {
        width: 110px;
        height: 100%;
      }
      .content {
        padding: 1.5rem;
        font-size: 12px;
      }
      h4 {
        ${isJapanese() ?
          css`
            font-family: ${ theme.font.fontFamilyDefault };
            font-weight: ${ theme.font.fontWeightBold };
          `
          : css`font-family: ${ theme.font.fontFamilyAlt };`
        };
        font-size: 13px;
        margin: 0 0 1rem 0;
        a {
          text-decoration: none;
        }
      }
      .link {
        margin-top: 2rem;
        font-weight: bold;
        svg {
          height: 1rem;
          color: ${theme.colours.primaryTint1};
          margin-right: 1rem;
        }
      }
    }
  `}
`

const StyledClusterMarker = styled.div<{ count: number }>`
  ${({ theme, count }): CSSProp => css`
    transform: translate(-50%, -50%);
    background: ${theme.colours.secondary};
    height: ${count >= 100 ? '4.8rem' : '3.7rem'};
    width: ${count >= 100 ? '4.8rem' : '3.7rem'};
    border-radius: 50%;
    border: solid ${theme.colours.secondary} 3px;
    color: ${theme.colours.primary};
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`

const StyledMap = styled.div`
  ${({ theme }): CSSProp => css`
    height: 100vh;

    > div {
      height: 100%;
      width: 100%;
    }
    .hide-map-button {
      position: absolute;
      top: 2rem;
      right: 3rem;
      border: solid #979797 1px;
      border-radius: 2px;
      background: none;
      padding: 1rem 2.5rem;
      color: #676767;
      font-size: 12px;
      cursor: pointer;
      @media only screen and ${theme.breakpoints.toMediumScreen} {
        display: none;
      }
    }
    .close-map-button {
      position: absolute;
      top: 2rem;
      right: 2rem;
      width: 37px;
      height: 37px;
      border: solid #26140f 1px;
      border-radius: 50%;
      background: white;
      padding: 0;
      color: #26140f;
      cursor: pointer;
      @media only screen and ${theme.breakpoints.fromMediumScreen} {
        display: none;
      }
      svg {
        height: 12px;
        position: relative;
        top: 2px;
      }
    }
    .ais-GeoSearch-map {
      height: 100%;
    }
  `}
`

type GeoSearchProps = GeoSearchProvided & {
  userLocation?: { lat: number; lng: number }
  userRegion?: string
  query: string
  setPlacesApi: any
  tag: string
  roastery: boolean
}

const GeoSearch = ({
  hits,
  refine,
  userLocation,
  userRegion,
  query,
  setPlacesApi,
  tag,
  roastery,
}: GeoSearchProps): JSX.Element => {
  const [activeMarker, setActiveMarker] = useState(null)
  const mapRef = useRef<any>()
  const [bounds, setBounds] = useState(null)
  const [center, setCenter] = useState(null)
  const [zoom, setZoom] = useState(10)
  const { state, dispatch } = useContext(FinderContext)
  const [loaded, setLoaded] = useState(false)
  const [gmaps, setGmaps] = useState(null)

  const points: PointFeature<any>[] = hits.map(hit => ({
    type: 'Feature',
    properties: { cluster: false, hit },
    geometry: {
      type: 'Point',
      coordinates: [parseFloat(hit._geoloc.lng), parseFloat(hit._geoloc.lat)],
    },
  }))

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds: bounds
      ? [bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat]
      : null,
    zoom,
    options: { radius: 105, maxZoom: 20 },
  })

  const MapMarker = ({ hit }: { hit: any }): JSX.Element => {
    const { objectID, title, slug, longAddress, images } = hit
    const handleMarkerClick = (e: SyntheticEvent): void => {
      e.stopPropagation()
      setActiveMarker(activeMarker === objectID ? null : objectID)
    }

    return (
      <StyledMapMarker
        onClick={(e): void => handleMarkerClick(e)}
        style={{ zIndex: activeMarker === objectID ? 1000 : null }}
      >
        { hit.independent ? <Coffee /> : <Roastery /> }
        {activeMarker === objectID && (
          <div className="popup">
            {images && (
              <Link to={`/find/${slug}/`}>
                <Img
                  fluid={{
                    ...images[0].fluid,
                    aspectRatio: 1,
                  }}
                />
              </Link>
            )}
            <div className="content">
              <h4>
                <Link to={`/find/${slug}/`}>{title}</Link>
              </h4>
              {longAddress}
              {hit.googlePlacesId && (
                <div className="link">
                  <LinkIcon />
                  <Link to={`https://www.google.com/maps/place/?q=place_id:${hit.googlePlacesId}`}>
                    {useResourceTextData('global.googlemaps', 'Google maps')}
                  </Link>
                </div>
              )}
            </div>
          </div>
        )}
      </StyledMapMarker>
    )
  }

  type ClusterMarkerProps = {
    id: number
    pointCount: number
    lat: number
    lng: number
  }
  const ClusterMarker = ({
    id,
    pointCount,
    lat,
    lng,
  }: ClusterMarkerProps): JSX.Element => (
    <StyledClusterMarker
      count={pointCount}
      onClick={(): void => {
        const expansionZoom = Math.min(
          supercluster.getClusterExpansionZoom(id),
          20
        )
        mapRef.current.setZoom(expansionZoom)
        mapRef.current.panTo({ lat, lng })
      }}
    >
      {pointCount}
    </StyledClusterMarker>
  )

  const zoomToRegion = (gmaps, userRegion?: string): void => {
    const bounds = new gmaps.LatLngBounds()
    const [northEast, southWest] = regions[
      userRegion || process.env.GATSBY_BUILD_LOCALE
    ].location.bounds
    bounds.extend(new gmaps.LatLng(...northEast))
    bounds.extend(new gmaps.LatLng(...southWest))

    mapRef.current.fitBounds(bounds)
    mapRef.current.panToBounds(bounds)
  }

  const handleApiLoaded = ({ map, maps }): void => {
    setGmaps(maps)
    mapRef.current = map

    // restore saved position
    // do not restore if searching by tag or roastery
    if (state?.center && !tag && !roastery) {
      const { center, zoom } = state
      zoom && mapRef.current.setZoom(zoom)
      center &&
        mapRef.current.panTo({
          ...center,
        })
    } else {
      // set default position
      zoomToRegion(maps)
    }
    setLoaded(true)
  }

  const handleMapClick = (): void => {
    setActiveMarker(null)
  }

  const refineMap = (bounds): void => {
    // workaround for algolia api cannot handle longitude > 180
    let northEastLng = bounds.ne.lng
    northEastLng = northEastLng > 180 ? 180 : northEastLng
    let southWestLng = bounds.sw.lng
    southWestLng = southWestLng > 180 ? 180 : southWestLng

    refine({
      northEast: {
        lat: bounds.ne.lat,
        lng: northEastLng,
      },
      southWest: {
        lat: bounds.sw.lat,
        lng: southWestLng,
      },
    })
  }

  const handleMapChange = ({ center, zoom, bounds }): void => {
    setCenter(center)
    setZoom(zoom)
    setBounds(bounds)
    if (!query && loaded) {
      refineMap(bounds)
    }
  }

  // fit map to hits
  const fitHitsBounds = (): void => {
    if (gmaps && hits.length > 0) {
      const bounds = new gmaps.LatLngBounds()
      hits.forEach(({ _geoloc }) => {
        bounds.extend(new gmaps.LatLng(_geoloc.lat, _geoloc.lng))
      })
      mapRef.current.fitBounds(bounds, 50) // 50 = bounds padding
      mapRef.current.panToBounds(bounds)
    }
  }

  // save current center and zoom into localstorage and state
  useEffect(() => {
    if (loaded) {
      localStorage.setItem(
        'finderState',
        JSON.stringify({
          center,
          zoom,
          date: Date.now(),
        })
      )
      dispatch({
        type: 'updateLocation',
        center,
        zoom,
      })
    }
  }, [center, zoom])

  // fit pins on map if hits updated
  useEffect(() => {
    if (query) {
      fitHitsBounds()
    }
  }, [hits])

  useEffect(() => {
    if (userLocation) {
      mapRef.current?.setZoom(USER_LOCATION_ZOOM)
      mapRef.current?.panTo({ ...userLocation })
    }
  }, [mapRef, userLocation])

  useEffect(() => {
    if (gmaps) {
      zoomToRegion(gmaps, userRegion)
    }
  }, [userRegion])

  // reset refinement on query change
  useEffect(() => {
    refine(null)
    if (!query && gmaps) {
      zoomToRegion(gmaps)
    }
  }, [query])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (loaded && gmaps) {
        setPlacesApi(
          new gmaps.places.PlacesService(document.createElement('div'))
        )
      }
    }, GOOGLE_MAPS_API_INITIAL_DELAY)
    return (): void => clearTimeout(timer)
  }, [loaded, gmaps])

  return (
    <GoogleMapReact
      bootstrapURLKeys={{
        key: process.env.GATSBY_GOOGLE_MAPS_API_KEY,
        libraries: 'places',
      }}
      defaultCenter={DEFAULT_CENTER}
      defaultZoom={DEFAULT_ZOOM}
      options={{
        fullscreenControl: false,
        minZoom: MIN_ZOOM,
        maxZoom: MAX_ZOOM,
        clickableIcons: false,
      }}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={handleApiLoaded}
      onClick={handleMapClick}
      onChange={handleMapChange}
    >
      {clusters.map(cluster => {
        const [longitude, latitude] = cluster.geometry.coordinates
        const {
          cluster: isCluster,
          point_count: pointCount,
          hit,
        } = cluster.properties

        return isCluster ? (
          <ClusterMarker
            key={`cluster-${cluster.id}`}
            lat={latitude}
            lng={longitude}
            pointCount={pointCount}
            id={cluster.id}
          />
        ) : (
          <MapMarker
            key={hit.objectID}
            lat={latitude}
            lng={longitude}
            hit={hit}
          />
        )
      })}
    </GoogleMapReact>
  )
}
const CustomGeoSearch = connectGeoSearch(GeoSearch)

type MapProps = {
  toggleView: () => void
  userLocation: { lat: number; lng: number }
  userRegion?: string
  query: string
  setPlacesApi: any
  tag: string
  roastery: boolean
}
const Map = ({ toggleView, userLocation, ...rest }: MapProps): JSX.Element => {
  const [ne, sw] = regions[process.env.GATSBY_BUILD_LOCALE].location.bounds
  return (
    <StyledMap>
      <CustomGeoSearch
        defaultRefinement={
          userLocation
            ? null
            : {
                northEast: {
                  lat: ne[0],
                  lng: ne[1],
                },
                southWest: {
                  lat: sw[0],
                  lng: sw[1],
                },
              }
        }
        userLocation={userLocation}
        {...rest}
      />
      {/* hide for now
      <button className="hide-map-button" type="button">
        {useResourceTextData('cafeFinder.hideMap', 'Hide Map')}
      </button> */}
      <button
        className="close-map-button"
        type="button"
        onClick={(): void => toggleView()}
      >
        <Close />
      </button>
    </StyledMap>
  )
}

export default Map
