import Link from 'components/atoms/link'
import OpenStatus from 'components/molecules/openStatus'
import { LoadMore } from 'components/organisms/articlesList'
import { graphql, useStaticQuery } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'
import FinderContext from 'helpers/finderContext'
import { getLocalNodes } from 'helpers/locale'
import { renderLineBreaks } from 'helpers/text'
import { triggerEvent } from 'helpers/tracking'
import usePlacesApi from 'hooks/usePlacesApi'
import { useResourceTextData } from 'hooks/useResourceTextData'
import ChevDown from 'images/svg/chev-down.svg'
import LinkIcon from 'images/svg/link.svg'
import { SearchResultsMsg } from 'pages/community/search-results'
import React, { useContext, useEffect, useState } from 'react'
import {
  Hit,
  HitsProvided,
  RefinementListProvided,
  StateResultsProvided,
} from 'react-instantsearch-core'
import {
  connectHits,
  connectRefinementList,
  connectStateResults,
} from 'react-instantsearch-dom'
import styled, { css, CSSProp } from 'styled-components'

const HITS_PER_PAGE = 10

const StyledListing = styled.div`
  ${({ theme }): CSSProp => css`
    font-size: 13px;
    padding: 3rem 0;
    @media only screen and ${theme.breakpoints.fromLargeScreen} {
      display: flex;
    }
    > div:first-child {
      margin-bottom: 2rem;
      @media only screen and ${theme.breakpoints.fromMediumScreen} {
        width: 195px;
      }
      @media only screen and ${theme.breakpoints.fromLargeScreen} {
        order: 1;
      }
    }
    > div:last-child {
      flex: 1;
    }
    h3 {
      font-size: 16px;
      font-weight: normal;
      a {
        text-decoration: none;
      }
    }
    .gatsby-image-wrapper {
      @media only screen and ${theme.breakpoints.fromMediumScreen} {
        width: 195px;
      }
    }
    svg {
      color: ${theme.colours.primaryTint1};
      height: 1.1rem;
      margin-right: 1rem;
      position: relative;
      top: 2px;
    }
    .link {
      font-size: 11px;
      margin-top: 0.8rem;
    }
    .row {
      display: flex;
      > div:first-child {
        flex: 1;
      }
      > div:nth-child(2) {
        margin-left: 10%;
        flex: 1;
      }
    }
  `}
`

const Filters = styled.div`
  ${({ theme }): CSSProp => css`
    .title {
      position: relative;
      width: 19.5rem;
      background: none;
      font-size: 12px;
      letter-spacing: 1.2px;
      font-weight: ${theme.font.fontWeightBold};
      text-transform: uppercase;
      padding: 1rem 0;
      margin-bottom: 30px;
      text-align: left;
      border: none;
      border-bottom: solid ${theme.colours.primaryTint1} 1px;
      cursor: pointer;
      &.expanded svg {
        transform: rotate(180deg);
      }
      &.hidden {
        display: none;
      }
      span {
        font-weight: normal;
        &.hidden {
          display: none;
        }
      }
      svg {
        transition: all ${theme.animation.defaultLength} ease;
        transform-origin: center 2px;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        pointer-events: none;
        height: 0.7rem;
      }
    }
    .clear-all {
      background: none;
      border: none;
      text-decoration: underline;
      margin: 0.3rem 0 0 5rem;
      cursor: pointer;
      &.hidden {
        display: none;
      }
    }
    .body {
      max-height: 0;
      transition: all ${theme.animation.defaultLength} ease;
      overflow: hidden;
      &.expanded {
        max-height: 50rem;
      }
    }

    .filter {
      display: inline-block;
      border: solid ${theme.colours.primary} 1px;
      border-radius: 2px;
      background: none;
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 1.2px;
      margin: 0 10px 13px 0;
      transition: all ${theme.animation.defaultLength} ease;
      padding: 8px 15px 7px;
      cursor: pointer;
      &.active {
        background-color: ${theme.colours.primary};
        color: ${theme.colours.secondary};
      }
    }
  `}
`

const Hits = ({
  hits,
  placesApi,
}: HitsProvided<any> & { placesApi: any }): JSX.Element => {
  const [page, setPage] = useState(0)

  const hitsToShow = hits?.slice(0, page * HITS_PER_PAGE + HITS_PER_PAGE)
  const loadMoreText = useResourceTextData('cafeFinder.loadMore', 'Load more')

  useEffect(() => {
    setPage(0)
  }, [hits.length])

  return placesApi ? (
    <>
      {hitsToShow.map((hit, k) => (
        <Listing key={hit.objectID} hit={hit} placesApi={placesApi} i={k} />
      ))}
      {hitsToShow.length < hits.length && (
        <LoadMore onClick={(): void => setPage(page + 1)}>
          {loadMoreText}
        </LoadMore>
      )}
    </>
  ) : null
}
export const CustomHits = connectHits(Hits)

const Listing = ({
  hit,
  placesApi,
  i,
}: {
  hit: Hit & { images: [{ fluid: FluidObject }] }
  placesApi: any
  i: number
}): JSX.Element => {
  const {
    objectID,
    title,
    slug,
    longAddress,
    openingHours,
    phone,
    images,
    googlePlacesId,
  } = hit

  const { dispatch } = useContext(FinderContext)

  const [ignoreOpeningHours, setIgnoreOpeningHours ] = useState(true)

  const googlePlace = usePlacesApi({
    api: placesApi,
    placeId: googlePlacesId,
    delay: i, //TODO delay is too long on subsequent pages
    ignoreOpeningHours: ignoreOpeningHours
  })

  useEffect(() => {
    if (googlePlace) {
      dispatch({
        type: 'updateHitsUrl',
        id: objectID,
        url: googlePlace.url,
      })
    }
  }, [dispatch, googlePlace, objectID])

  const getOpeningHours = (): void => {
    triggerEvent({
      action: 'check_open_status',
      category: 'cafe_finder',
      label: title,
    })
    setIgnoreOpeningHours(false)
  }


  const googleMapsText = useResourceTextData('global.googlemaps', 'Google maps')

  return (
    <StyledListing>
      <div>
        {images && (
          <Link to={`/find/${slug}/`}>
            <Img fluid={{ ...images[0].fluid, aspectRatio: 1.5 }} />
          </Link>
        )}
      </div>
      <div>
        <h3>
          <Link to={`/find/${slug}/`}>{title}</Link>
        </h3>
        <div className="row">
          <div>
            {renderLineBreaks(longAddress)}
            <div>{phone}</div>
            <div
              className="link"
              style={{ visibility: googlePlacesId ? 'visible' : 'hidden' }}
            >
              <LinkIcon />
              <Link to={`https://www.google.com/maps/place/?q=place_id:${googlePlacesId}`}>{googleMapsText}</Link>
            </div>
          </div>
          <div>
            {renderLineBreaks(openingHours)}
            {openingHours && (
              <>
                <br />
                <br />
              </>
            )}

            <OpenStatus status={googlePlace?.openStatus || 'unknown'} getOpeningHours={getOpeningHours} />
          </div>
        </div>
      </div>
    </StyledListing>
  )
}

const RefinementList = ({
  items,
  refine,
  currentRefinement,
  query,
}: RefinementListProvided & { query: string; tags: any[] }): JSX.Element => {
  const [expanded, setExpanded] = useState(false)
  const [loaded, setLoaded] = useState(false)

  const cafeFinderFiltersText = useResourceTextData(
    'cafeFinder.filters',
    'Filters'
  )
  const cafeFinderAppliedText = useResourceTextData(
    'cafeFinder.applied',
    'applied'
  )
  const cafeFinderClearAllText = useResourceTextData(
    'cafeFinder.clearAll',
    'Clear all'
  )
  useEffect(() => {
    if (loaded) {
      refine([])
    }
  }, [loaded, query, refine])

  useEffect(() => {
    setLoaded(true)
  }, [])

  const { allContentfulCafeFilterTag } = useStaticQuery(
    graphql`
      query {
        allContentfulCafeFilterTag {
          nodes {
            id
            title
            slug
            node_locale
          }
        }
      }
    `
  )
  const tags = getLocalNodes(allContentfulCafeFilterTag.nodes)

  if (items.length === 0) {
    return null
  }
  return (
    <Filters>
      <div>
        <button
          type="button"
          className={`title ${expanded ? 'expanded' : ''} ${
            items.length === 0 ? 'hidden' : ''
          }`}
          onClick={(): void => setExpanded(!expanded)}
        >
          {cafeFinderFiltersText}{' '}
          <span
            className={
              currentRefinement.length === 0 || expanded ? 'hidden' : ''
            }
          >
            ({currentRefinement.length} {cafeFinderAppliedText})
          </span>
          <ChevDown />
        </button>

        <button
          type="button"
          className={`clear-all ${
            currentRefinement.length === 0 ? 'hidden' : ''
          }`}
          onClick={(): void => refine([])}
        >
          {cafeFinderClearAllText}
        </button>
      </div>

      <div className={`body ${expanded ? 'expanded' : ''}`}>
        {items.map(item => {
          const tag = tags.find(x => x.slug === item.label)
          return tag ? (
            <button
              key={item.label}
              className={`filter ${item.isRefined ? 'active' : ''}`}
              onClick={(): void => refine(item.value)}
            >
              {tag.title}
            </button>
          ) : null
        })}
      </div>
    </Filters>
  )
}
export const CustomRefinementList = connectRefinementList(RefinementList)

const StateResults = ({
  searching,
  searchResults,
  placesApi,
}: StateResultsProvided): JSX.Element => {
  if (searching) {
    return null
  }
  const hasResults = searchResults && searchResults.nbHits !== 0
  const textResultFound = useResourceTextData(
    'community.resultFound',
    'result found'
  )
  const textResultsFound = useResourceTextData(
    'community.resultsFound',
    'results found'
  )
  const noResultsHeading = useResourceTextData(
    'cafeFinder.noResultsHeading',
    'Oops! It doesn’t look like there are any results that match your requirements.'
  )
  const noResultsBody = useResourceTextData(
    'cafeFinder.noResultsMessage',
    'Try applying different filters or looking in another location.'
  )

  return placesApi ? (
    <>
      {hasResults ? (
        <SearchResultsMsg className="count">
          <strong>{searchResults?.nbHits}</strong>{' '}
          {searchResults?.nbHits === 1 ? textResultFound : textResultsFound}
        </SearchResultsMsg>
      ) : (
        <div className="no-results">
          <h3>{noResultsHeading}</h3>
          <p>{noResultsBody}</p>
        </div>
      )}
    </>
  ) : null
}
export const CustomStateResults = connectStateResults(StateResults)
