import Link from 'components/atoms/link'
import Select from 'components/atoms/select'
import { TextFieldStyles } from 'components/atoms/textField'
import Layout from 'components/layout'
import {
  CustomHits,
  CustomRefinementList,
  CustomStateResults,
} from 'components/molecules/find-widgets'
import Seo from 'components/molecules/seo'
import { algoliaClient } from 'components/organisms/algolia'
import Map from 'components/organisms/map'
import { graphql, PageProps } from 'gatsby'
import { getCurrentLang, isJapanese } from 'helpers/locale'
import { regions } from 'helpers/locale'
import { useResourceTextData } from 'hooks/useResourceTextData'
import Close from 'images/svg/close.svg'
import Marker from 'images/svg/marker.svg'
import { debounce, orderBy } from 'lodash'
import React, { SyntheticEvent, useState } from 'react'
import { useCookies } from 'react-cookie'
import { Configure, InstantSearch, SearchBox } from 'react-instantsearch-dom'
import styled, { css, CSSProp } from 'styled-components'
import { Site } from 'types/graphql-types'

const FIND_BY_TYPE_CAFE = 'cafe'
const FIND_BY_TYPE_ROASTERY = 'roastery'
const SEARCH_BOX_DEBOUNCE = 500
const USER_LOCATION_LOCATING = 'locating'
const USER_LOCATION_FOUND = 'found'
const USER_LOCATION_ERROR = 'error'

const StyledFinder = styled.div`
  ${({ theme }): CSSProp => css`
    .ais-Hits-list {
      margin: 0;
      padding: 0;
      list-style: none;
    }
    .ais-Hits-item + .ais-Hits-item {
      border-top: solid ${theme.colours.lightTint3} 1px;
    }
    .ais-SearchBox-submit {
      display: none;
    }
    .pagination {
      margin: 1rem 0 0 0;
      padding: 0;
      list-style: none;
      text-align: right;
      li {
        display: inline-block;
        margin: 0 4px;
        font-size: 14px;
      }
      a {
        width: 4rem;
        height: 4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
      }
      .active {
        background-color: ${theme.colours.light};
      }
      .next,
      .prev {
        border: solid 1px #26140f;
        border-radius: 2px;
        svg {
          width: 13px;
        }
      }
      .next svg {
        transform: rotate(-90deg);
      }
      .prev svg {
        transform: rotate(90deg);
      }
    }

    .no-results {
      @media only screen and ${theme.breakpoints.fromLargeScreen} {
        margin-top: 3rem;
      }
      h3 {
        ${isJapanese()
          ? css`
              font-family: ${theme.font.fontFamilyDefault};
              font-weight: ${theme.font.fontWeightBold};
            `
          : css`
              font-family: ${theme.font.fontFamilyAlt};
            `};
        line-height: 1.4;
        margin-bottom: 1rem;
      }
      p {
        margin-top: 0;
      }
    }
  `}
`

const MapViewButton = styled.button`
  ${({ theme }): CSSProp => css`
    background: ${theme.colours.primaryTint1};
    color: white;
    border-radius: 2rem;
    padding: 10px 20px;
    font-weight: bold;
    font-size: 12px;
    box-shadow: 0 3px 10px 0 rgba(122, 95, 63, 0.47);
    display: block;
    margin: 0 auto 3rem;
    position: sticky;
    bottom: 5rem;
    @media only screen and ${theme.breakpoints.fromMediumScreen} {
      display: none;
    }
    svg {
      height: 13px;
      position: relative;
      top: 1px;
    }
  `}
`

const Row = styled.div`
  ${({ theme }): CSSProp => css`
    @media only screen and ${theme.breakpoints.fromMediumScreen} {
      display: flex;
    }
    > div {
      @media only screen and ${theme.breakpoints.fromMediumScreen} {
        flex: 1;
        min-height: calc(100vh - ${theme.layout.headerHeightMobile});
        width: 50%;
      }
      @media only screen and ${theme.breakpoints.fromLargeScreen} {
        min-height: calc(100vh - ${theme.layout.headerHeightDesktop});
      }
    }
    .lhs {
      max-width: 83rem;
      margin-left: auto;
      @media only screen and ${theme.breakpoints.fromMediumScreen} {
        padding-left: 4rem;
      }
    }
  `}
`

const Search = styled.div`
  ${({ theme }): CSSProp => css`
    background-color: ${theme.colours.lightTint3};
    position: relative;
    padding: 3rem 2rem;
    width: auto;
    @media only screen and ${theme.breakpoints.fromMediumScreen} {
      margin: 0;
      padding: 4.5rem 3rem 4.5rem 0;
    }
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 100%;
      width: 50vw;
      height: 100%;
      background-color: ${theme.colours.lightTint3};
    }
    ul {
      margin: 0 0 2rem;
      padding: 0;
      list-style: none;
      @media only screen and ${theme.breakpoints.fromLargeScreen} {
        margin: 0 0 3rem;
      }
    }
    li {
      display: inline;
      + li {
        margin-left: 4rem;
      }
      a {
        text-decoration: none;
        padding: 3px 0;
        text-transform: uppercase;
        letter-spacing: 1.2px;
        font-size: 12px;
        cursor: pointer;
        &.active {
          font-weight: ${theme.font.fontWeightBold};
          border-bottom: solid 2px;
        }
      }
    }
    .ais-SearchBox-form {
      max-width: 46.4rem;
      position: relative;
    }
    .ais-SearchBox-reset {
      background: none;
      border: none;
      position: absolute;
      top: 2rem;
      right: 1.6rem;
      cursor: pointer;
      svg {
        height: 1.2rem;
      }
    }

    input {
      ${TextFieldStyles}
      margin-bottom: 1rem;

      &::-webkit-search-cancel-button {
        -webkit-appearance: none;
      }
      &::-webkit-search-decoration,
      &::-webkit-search-cancel-button,
      &::-webkit-search-results-button,
      &::-webkit-search-results-decoration {
        display: none;
      }
    }
    .explore {
      margin-top: 2.5rem;
      &,
      button {
        font-size: 11px;
        text-transform: uppercase;
        font-weight: ${theme.font.fontWeightBold};
        letter-spacing: 1px;
        padding: 0;
      }
      button {
        background: none;
        border: none;
        text-decoration: underline;
        cursor: pointer;
      }
      svg {
        height: 1.5rem;
        position: relative;
        top: -2px;
        vertical-align: middle;
        margin: 0 0.4rem 0 1rem;
      }
    }
    .select {
      display: block;
      max-width: 46.4rem;
    }
  `}
`

const ListWrap = styled.div<{ view: string }>`
  ${({ theme, view }): CSSProp => css`
    position: relative;
    margin-top: 3rem;
    @media only screen and ${theme.breakpoints.toMediumScreen} {
      padding: 0 2rem;
      display: ${view === 'list' ? 'block' : 'none'};
    }
    @media only screen and ${theme.breakpoints.fromMediumScreen} {
      padding-right: 2rem;
      padding-right: 10%;
      padding-bottom: 10rem;

      .count {
        @media only screen and ${theme.breakpoints.fromMediumScreen} {
          position: absolute;
          top: 8px;
          right: 2rem;
        }
        @media only screen and ${theme.breakpoints.fromLargeScreen} {
          right: 5.8rem;
        }
      }
    }
  `}
`

const MapWrap = styled.div<{ view: string }>`
  ${({ theme, view }): CSSProp => css`
    position: relative;
    @media only screen and ${theme.breakpoints.toMediumScreen} {
      display: ${view === 'map' ? 'block' : 'none'};
    }
    @media only screen and ${theme.breakpoints.fromMediumScreen} {
      margin: 0;
      .sticky {
        position: sticky;
        top: ${theme.layout.headerHeightMobile};
        height: calc(100vh - ${theme.layout.headerHeightMobile});
        @media only screen and ${theme.breakpoints.fromLargeScreen} {
          top: ${theme.layout.headerHeightDesktop};
          height: calc(100vh - ${theme.layout.headerHeightDesktop});
        }
        > div {
          height: 100%;
        }
      }
    }
  `}
`

type FindTypes = PageProps & {
  data: {
    site: Site
  }
}

const Find = ({ location, pageContext }: FindTypes): JSX.Element => {
  const [view, setView] = useState('list')
  const [userLocationStatus, setUserLocationStatus] = useState('')
  const [userLocation, setUserLocation] = useState(null)
  const [userRegion, setUserRegion] = useState(process.env.GATSBY_BUILD_LOCALE)
  const [query, setQuery] = useState('')
  const [placesApi, setPlacesApi] = useState()
  const { findByType = FIND_BY_TYPE_CAFE } = pageContext
  const tag = location.search.replace('?tag=', '')
  const [cookies] = useCookies(['allpress'])

  const toggleView = (): void => {
    setView(view === 'list' ? 'map' : 'list')
  }

  const findByUserLocation = (): void => {
    setUserLocationStatus(USER_LOCATION_LOCATING)
    navigator.geolocation.getCurrentPosition(
      position => {
        setUserRegion(cookies.detected_origin)
        setUserLocationStatus(USER_LOCATION_FOUND)
        const { latitude: lat, longitude: lng } = position.coords
        setUserLocation({
          lat,
          lng,
        })
      },
      () => {
        // unable to locate user
        console.log('unable to locate user')
        setUserLocationStatus(USER_LOCATION_ERROR)
      }
    )
  }

  const refineQuery = debounce(
    e => setQuery(e.target.value),
    SEARCH_BOX_DEBOUNCE
  )

  return (
    <Layout header="light">
      <Seo
        title={
          findByType !== FIND_BY_TYPE_ROASTERY
            ? useResourceTextData(
                'cafeFinder.metaTitle',
                'Allpress Espresso Cafe Finder'
              )
            : useResourceTextData(
                'cafeFinderRoastery.metaTitle',
                'Allpress Espresso Cafe Finder'
              )
        }
        postTitle={
          findByType !== FIND_BY_TYPE_ROASTERY
            ? useResourceTextData(
                'cafeFinder.metaPostTitle',
                'Find The Best Cafes Near You'
              )
            : useResourceTextData(
                'cafeFinderRoastery.metaPostTitle',
                'Our Flagship Cafes and Roasteries'
              )
        }
        description={
          findByType !== FIND_BY_TYPE_ROASTERY
            ? useResourceTextData(
                'cafeFinder.metaDescription',
                'Find the best cafes for your next holiday or on the go. Search over 1500 Allpress cafes around the world to find your new favourite coffee shop.'
              )
            : useResourceTextData(
              'cafeFinderRoastery.metaDescription',
              'All of our Allpress Espresso Roasteries and Flagship Cafes are listed on the Cafe Finder. From Auckland and Melbourne to Sydney, London and Tokyo. '
            )
        }
        url={location.href}
      />
      <StyledFinder>
        <InstantSearch indexName="Cafes" searchClient={algoliaClient}>
          <Configure
            hitsPerPage={1000}
            filters={`node_locale:${getCurrentLang()} ${
              findByType === FIND_BY_TYPE_CAFE ? '' : 'AND independent:false'
            } AND region:${userRegion}`}
            aroundLatLng={
              userLocation ? `${userLocation.lat}, ${userLocation.lng}` : ''
            }
          />
          <Row>
            <div>
              <div className="lhs">
                <Search>
                  <ul>
                    <li>
                      <Link
                        to="/find/"
                        className={
                          findByType === FIND_BY_TYPE_CAFE ? 'active' : ''
                        }
                      >
                        {useResourceTextData(
                          'cafeFinder.findACafe',
                          'Find a Café'
                        )}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/find/roastery/"
                        className={
                          findByType === FIND_BY_TYPE_ROASTERY ? 'active' : ''
                        }
                      >
                        {useResourceTextData(
                          'cafeFinder.findARoastery',
                          'Find a Roastery'
                        )}
                      </Link>
                    </li>
                  </ul>

                  <div
                    style={{
                      display:
                        findByType === FIND_BY_TYPE_CAFE ? 'block' : 'none',
                    }}
                  >
                    <SearchBox
                      translations={{
                        placeholder: useResourceTextData(
                          'cafeFinder.searchPlaceholder',
                          'Search by suburb, city, postcode, etc'
                        ),
                      }}
                      reset={<Close />}
                      onChange={(e: SyntheticEvent): void => {
                        e.persist()
                        refineQuery(e)
                      }}
                      onReset={(): void => {
                        setQuery('')
                      }}
                    />

                    <Select
                      onChange={(
                        e: React.FormEvent<HTMLSelectElement>
                      ): void => {
                        setUserRegion(e.currentTarget.value)
                      }}
                      value={userRegion}
                    >
                      {Object.keys(regions).map(id => (
                        <option key={id} value={id}>
                          {regions[id].name}
                        </option>
                      ))}
                    </Select>
                  </div>
                  <div
                    style={{
                      display:
                        findByType === FIND_BY_TYPE_ROASTERY ? 'block' : 'none',
                    }}
                  >
                    <Select
                      onChange={(
                        e: React.FormEvent<HTMLSelectElement>
                      ): void => {
                        setUserRegion(e.currentTarget.value)
                      }}
                      defaultValue={process.env.GATSBY_BUILD_LOCALE}
                    >
                      {Object.keys(regions).map(id => (
                        <option key={id} value={id}>
                          {regions[id].name}
                        </option>
                      ))}
                    </Select>
                  </div>
                  <div
                    className="explore"
                    style={{
                      visibility:
                        findByType !== FIND_BY_TYPE_CAFE ? 'hidden' : 'visible',
                    }}
                  >
                    {useResourceTextData('cafeFinder.or', 'Or')}
                    <Marker />
                    {(userLocationStatus === '' ||
                      userLocationStatus === USER_LOCATION_FOUND) && (
                      <button type="button" onClick={findByUserLocation}>
                        {useResourceTextData(
                          'cafeFinder.exploreNearby',
                          'Explore locations nearby'
                        )}
                      </button>
                    )}
                    {userLocationStatus === USER_LOCATION_LOCATING && (
                      <span>
                        {useResourceTextData(
                          'cafeFinder.locatingUser',
                          'Locating user...'
                        )}
                      </span>
                    )}
                    {userLocationStatus === USER_LOCATION_ERROR && (
                      <span>
                        {useResourceTextData(
                          'cafeFinder.locatingUserError',
                          'Cannot Locate user'
                        )}
                      </span>
                    )}
                  </div>
                </Search>
                <ListWrap view={view}>
                  <CustomRefinementList
                    attribute="filterTags.slug"
                    transformItems={(items): any[] =>
                      orderBy(items, 'label', 'asc')
                    }
                    operator="and"
                    query={query}
                    limit={30}
                    defaultRefinement={tag ? [tag] : []}
                  />
                  <CustomStateResults placesApi={placesApi} />
                  <CustomHits placesApi={placesApi} />
                  <MapViewButton
                    type="button"
                    onClick={(): void => toggleView()}
                  >
                    <Marker />{' '}
                    {useResourceTextData('cafeFinder.mapView', 'Map view')}
                  </MapViewButton>
                </ListWrap>
              </div>
            </div>
            <MapWrap view={view}>
              <div className="sticky">
                <Map
                  toggleView={toggleView}
                  userLocation={userLocation}
                  userRegion={userRegion}
                  query={query}
                  setPlacesApi={setPlacesApi}
                  tag={tag}
                  roastery={findByType === FIND_BY_TYPE_ROASTERY}
                />
              </div>
            </MapWrap>
          </Row>
        </InstantSearch>
      </StyledFinder>
    </Layout>
  )
}

export default Find

export const pageQuery = graphql`
  query FindQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
